import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Select,
  Text
} from "@chakra-ui/react";

import api from "../api";
import { Babe, Scheduling } from "../types/types";
import { getDuration } from "../util";

function Approval() {
  const { t, i18n } = useTranslation();
  const { token } = useParams<{ token: string }>();

  const [loading, setLoading] = useState<boolean>(true);
  const [responded, setResponded] = useState<"declined" | "approved">();
  const [scheduling, setScheduling] = useState<Scheduling>();
  const [babe, setBabe] = useState<Babe>();

  useEffect(() => {
    getSchedulingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSchedulingDetails = async () => {
    setScheduling(await api.getScheduling(token));
    setLoading(false);
  };

  const renderTitle = () => {
    switch (responded) {
      default:
      case undefined:
        return t("approval:title");
      case "approved":
        return t("approval:approvedTitle");
      case "declined":
        return t("approval:declinedTitle");
    }
  };

  const handleDecline = async () => {
    setLoading(true);
    babe && setResponded(await api.declineScheduling(token, babe));
    setLoading(false);
  };

  const handleApprove = async () => {
    setLoading(true);
    babe && setResponded(await api.approveScheduling(token, babe));
    setLoading(false);
  };

  return (
    <>
      <Heading as="h1" size="md" mb={2}>
        {renderTitle()}
      </Heading>
      <Text color="gray.600" mb={4}>
        {responded ? t("approval:respondedSubtitle") : t("approval:subtitle")}
      </Text>

      {!responded && (
        <Box id="wrapper" boxShadow="base" rounded="md" bg="white" mb={4} p={4}>
          {loading ? (
            <Center h={32}>
              <CircularProgress
                color="blue.400"
                isIndeterminate
                capIsRound
                thickness={8}
              />
            </Center>
          ) : (
            scheduling && (
              <>
                <Heading as="h4" size="sm" mb={2}>
                  {t("confirm:dates")}
                </Heading>
                <Grid templateColumns="1fr 2fr" columnGap={2}>
                  <Text as="span" color="gray.600">
                    {t("confirm:from")}
                  </Text>
                  <Text>
                    {new Date(scheduling.from)?.toLocaleString(i18n.language, {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                      year: "numeric"
                    })}
                  </Text>

                  <Text as="span" color="gray.600">
                    {t("confirm:to")}
                  </Text>
                  <Text>
                    {new Date(scheduling.to)?.toLocaleString(i18n.language, {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                      year: "numeric"
                    })}
                  </Text>

                  <Text as="span" color="gray.600">
                    {t("confirm:duration")}
                  </Text>
                  <Text>
                    {scheduling?.from &&
                      scheduling?.to &&
                      `${
                        getDuration(
                          new Date(scheduling.from),
                          new Date(scheduling.to)
                        ) + 1
                      } ${t("confirm:days")}, ${getDuration(
                        new Date(scheduling.from),
                        new Date(scheduling.to)
                      )} ${t("confirm:nights")}`}
                  </Text>
                </Grid>

                <Divider my={4} />

                <Heading as="h4" size="sm" mb={2}>
                  {t("confirm:contact")}
                </Heading>
                <Grid templateColumns="1fr 2fr" columnGap={2}>
                  <Text as="span" color="gray.600">
                    {t("confirm:name")}
                  </Text>
                  <Text>
                    {`${scheduling.who.firstName} ${scheduling.who.lastName}`}
                  </Text>

                  <Text as="span" color="gray.600">
                    {t("contact:email")}
                  </Text>
                  <Text>{scheduling.who.email}</Text>

                  <Text as="span" color="gray.600">
                    {t("contact:phone")}
                  </Text>
                  <Text>{scheduling.who.phone}</Text>
                </Grid>
              </>
            )
          )}
        </Box>
      )}

      {scheduling && !responded && (
        <>
          <FormControl id="email" mb={4} isRequired>
            <FormLabel>{t("approval:respondAs")}</FormLabel>
            <Select
              placeholder={t("approval:selectBabe")}
              value={babe}
              onChange={(ev) => setBabe(ev.target.value as Babe)}
            >
              <option value="joao">Joao</option>
              <option value="sara">Sara</option>
            </Select>
          </FormControl>
          <Grid gap={4} templateColumns="1fr 1fr">
            <Button colorScheme="red" disabled={!babe} onClick={handleDecline}>
              {t("approval:decline")}
            </Button>
            <Button
              colorScheme="green"
              disabled={!babe}
              onClick={handleApprove}
            >
              {t("approval:approve")}
            </Button>
          </Grid>
        </>
      )}
    </>
  );
}

export default Approval;
