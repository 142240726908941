import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, IconButton, Text, Grid } from "@chakra-ui/react";
import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded
} from "@material-ui/icons";

import api from "../api";
import { Scheduling } from "../types/types";

import "./Calendar.css";

interface Day {
  date: Date;
  day: number;
  weekday: number;
  year: number;
  today: boolean;
  past: boolean;
  available: boolean;
  selected?: boolean;
  hovered?: boolean;
}

interface CalendarProps {
  setSelected: (from: Date | undefined, to: Date | undefined) => void;
}

function Calendar(props: CalendarProps) {
  const { t, i18n } = useTranslation();

  const [date, setDate] = useState<Date>(new Date());
  const [schedules, setSchedules] = useState<Array<Scheduling>>();

  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [hovering, setHovering] = useState<Day>();

  useEffect(() => {
    getAvailability();
  }, []);

  useEffect(() => {
    if (to && from && to < from) {
      setFrom(undefined);
      setTo(undefined);
    }

    props.setSelected(from, to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  const getAvailability = async () => {
    const scheduleData = await api.getSchedulings(new Date().getTime());
    setSchedules(scheduleData);
  };

  const calculateMonth = () => {
    let monthArray: Array<Array<any>> = [[]];

    if (date) {
      let currentMonth = date.getMonth();
      date.setDate(1); // Go to the first day of the month.

      /** Keep building the structure until it reaches a new month (end of the current month). */
      while (date.getMonth() === currentMonth) {
        /** If the day is Monday, create a new week array. */
        if (date.getDay() === 0) monthArray.push([]);

        let weekArray = monthArray[monthArray.length - 1];

        date.setHours(0, 0, 0, 0);

        /** Add the day to its respective week. */
        const day: Day = {
          date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
          day: date.getDate(),
          weekday: date.getDay(),
          year: date.getFullYear(),
          today: _isToday(date),
          past: date.getTime() < new Date().setHours(0, 0, 0, 0),
          available: _isAvailable(date),
          selected: _isSelected(date),
          hovered: _isSelectable(date) && hovering && date <= hovering.date
        };

        weekArray.push(day);

        /** Increment the day. */
        date.setDate(date.getDate() + 1);
      }

      /** Structure final clean-up: Erase the first week if it's empty and add null values to it if needed. */
      let firstWeek = monthArray[0];
      if (firstWeek.every((day) => day === 0)) monthArray.shift();
      while (firstWeek.length < 7) firstWeek.unshift(0);

      /** Reset the current date value. */
      date.setDate(1);
      date.setMonth(date.getMonth() - 1);
    }
    return monthArray;
  };

  /**
   * Maps the calculated month structure into table rows/cells.
   *
   * @memberof Calendar
   */
  const renderMonth = () => {
    const monthArray = calculateMonth();
    return monthArray.map((week, i) => (
      <Grid
        className="weekrow"
        key={i}
        templateColumns="repeat(7, 1fr)"
        gap={[0, 2]}
        mb={[0, 2]}
      >
        {week.map((day, j) => {
          let hasDay = day !== 0;
          let classList = "";

          /** Generate the cell's classList. */
          if (hasDay) classList += "day";
          if (day.today) classList += " today";
          if (day.past) classList += " past";
          if (!day.available) classList += " unavailable";
          if (day.selected) classList += " selected";
          if (day.hovered) classList += " hovered";
          if (isSelecting) classList += " selecting";

          return (
            <Box
              key={j}
              className={classList || undefined}
              title={!day.available ? t("calendar:unavailable") : undefined}
              onClick={_handleDayClick.bind(null, day)}
              onMouseEnter={_handleDayHover.bind(null, day)}
              tabIndex={day.available && !day.past ? 0 : -1}
            >
              {hasDay ? <Text fontSize={["sm", "md"]}>{day.day}</Text> : ""}
            </Box>
          );
        })}
      </Grid>
    ));
  };

  /**
   * Checks if a given date is today's date.
   *
   * @memberof Calendar
   * @param { Date } date The date to check.
   * @returns { Boolean }
   */
  const _isToday = (date: Date): boolean => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  /**
   * Checks if a given date doesn't clash with a confirmed inquiry.
   *
   * @memberof Calendar
   * @param { Date } date The date to check.
   * @returns { Boolean }
   */
  const _isAvailable = (date: Date): boolean => {
    if (!schedules || !schedules.length) return true;

    date.setHours(0, 0, 0, 0);

    return schedules
      .filter((sch) => sch.status === "confirmed")
      .every((sch) => {
        return !(date.getTime() >= sch.from && date.getTime() <= sch.to);
      });
  };

  /**
   * Checks if a given date can be selected.
   *
   * @memberof Calendar
   * @param { Date } date The date to check.
   * @returns { Boolean }
   */
  const _isSelectable = (date: Date): boolean => {
    if (!from || !isSelecting) return false;

    const hasSchedulingBetween = schedules?.some((sch) => {
      return sch.from >= from.getTime() && date.getTime() >= sch.to;
    });

    return from <= date && _isAvailable(date) && !hasSchedulingBetween;
  };

  const _isSelected = (date: Date): boolean => {
    if (!from || !to) return false;
    return from <= date && date <= to;
  };

  const _handleDayClick = (day: Day) => {
    if (!day || !day.available || day.past) return;

    if (!isSelecting) {
      setIsSelecting(true);
      setFrom(day.date);
      setTo(undefined);
    } else {
      if (!day.hovered) return;
      setIsSelecting(false);
      setTo(day.date);
    }
  };

  const _handleDayHover = (day: Day) => {
    setHovering(day);
  };

  return (
    <Box id="wrapper" boxShadow="base" rounded="md" bg="white" mb={6}>
      <Flex
        id="header"
        p={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton
          aria-label="Previous"
          icon={<KeyboardArrowLeftRounded />}
          variant="ghost"
          onClick={() => {
            const month = date.getMonth();
            const newDate = new Date(date);
            newDate.setMonth(month - 1);
            setDate(newDate);
          }}
        />
        <Text>
          {date.toLocaleString(i18n.language, {
            month: "long",
            year: "numeric"
          })}
        </Text>
        <IconButton
          aria-label="Next"
          icon={<KeyboardArrowRightRounded />}
          variant="ghost"
          onClick={() => {
            const month = date.getMonth();
            const newDate = new Date(date);
            newDate.setMonth(month + 1);
            setDate(newDate);
          }}
        />
      </Flex>

      <Grid
        id="weekdays"
        px={2}
        templateColumns="repeat(7, 1fr)"
        gap={[0, 2]}
        fontWeight="bold"
        color="gray.600"
        textAlign="center"
      >
        <Text fontSize={["xs", "sm"]}>{t("calendar:sunday")}</Text>
        <Text fontSize={["xs", "sm"]}>{t("calendar:monday")}</Text>
        <Text fontSize={["xs", "sm"]}>{t("calendar:tuesday")}</Text>
        <Text fontSize={["xs", "sm"]}>{t("calendar:wednesday")}</Text>
        <Text fontSize={["xs", "sm"]}>{t("calendar:thursday")}</Text>
        <Text fontSize={["xs", "sm"]}>{t("calendar:friday")}</Text>
        <Text fontSize={["xs", "sm"]}>{t("calendar:saturday")}</Text>
      </Grid>

      <Box id="month" px={2} pb={2}>
        {renderMonth()}
      </Box>
    </Box>
  );
}

export default Calendar;
