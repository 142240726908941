import { Box, Button, Grid, Heading, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Calendar from "../components/Calendar";

import "./Home.css";

interface HomeProps {
  selectedDates?: { from: Date | undefined; to: Date | undefined };
  setSelected: (from: Date | undefined, to: Date | undefined) => void;
  confirm: () => void;
}

function Home(props: HomeProps) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Heading as="h1" size="md" mb={2}>
        {t("home:title")}
      </Heading>
      <Text color="gray.600" mb={4}>
        {t("home:subtitle")}
      </Text>

      <Calendar setSelected={props.setSelected}></Calendar>

      <Grid templateColumns="1fr 2fr" gap={2}>
        <Box as="ul">
          <Heading as="h6" size="xs" mb={2} color="gray.600">
            {t("home:legend.title")}
          </Heading>

          <Box as="li" display="flex" alignItems="center">
            <div className="legend free"></div>
            <Text color="gray.600">{t("home:legend.free")}</Text>
          </Box>
          <Box as="li" display="flex" alignItems="center">
            <div className="legend booked"></div>
            <Text color="gray.600">{t("home:legend.booked")}</Text>
          </Box>
          <Box as="li" display="flex" alignItems="center">
            <div className="legend selected"></div>
            <Text color="gray.600">{t("home:legend.selected")}</Text>
          </Box>
        </Box>

        <Stack>
          <Stack>
            <Heading as="h6" size="xs" color="gray.600">
              {t("home:selectedDates")}
            </Heading>

            <Heading size="lg">
              {props.selectedDates?.from?.toLocaleString(i18n.language, {
                month: "short",
                day: "numeric"
              }) || (
                <Text as="span" color="gray.400">
                  --
                </Text>
              )}
              {" → "}
              {props.selectedDates?.to?.toLocaleString(i18n.language, {
                month: "short",
                day: "numeric"
              }) || (
                <Text as="span" color="gray.400">
                  --
                </Text>
              )}
            </Heading>
          </Stack>

          {props.selectedDates?.from && props.selectedDates?.to && (
            <Button colorScheme="blue" onClick={props.confirm}>
              {t("global:confirm")}
            </Button>
          )}
        </Stack>
      </Grid>
    </>
  );
}

export default Home;
