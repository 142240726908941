import { Babe } from "../types/types";
import { API_URL, POST_CONFIG } from "../util";

export const declineScheduling = async (
  id: string,
  by: Babe
): Promise<"declined" | undefined> => {
  try {
    await fetch(API_URL + `/decline`, {
      ...POST_CONFIG,
      body: JSON.stringify({
        id,
        by
      })
    });
    return "declined";
  } catch (error) {
    console.error(error);
  }
};

export const approveScheduling = async (
  id: string,
  by: Babe
): Promise<"approved" | undefined> => {
  try {
    await fetch(API_URL + `/approve`, {
      ...POST_CONFIG,
      body: JSON.stringify({
        id,
        by
      })
    });
    return "approved";
  } catch (error) {
    console.error(error);
  }
};
