import {
  Box,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Text
} from "@chakra-ui/react";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

interface ContactProps {
  setContact: (
    firstName: string,
    lastName: string,
    email: string,
    phone: string
  ) => void;
  cancel: () => void;
  confirm: () => void;
}

function Contact(props: ContactProps) {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault();

    props.setContact(firstName, lastName, email, phone);
    props.confirm();
  };

  return (
    <>
      <Heading as="h1" size="md" mb={2}>
        {t("contact:title")}
      </Heading>
      <Text color="gray.600" mb={4}>
        {t("contact:subtitle")}
      </Text>

      <Box
        as="form"
        id="form"
        boxShadow="base"
        rounded="md"
        bg="white"
        p={4}
        mb={6}
        onSubmit={handleSubmit}
      >
        <Grid gap={4} templateColumns="1fr 1fr">
          <FormControl id="firstName" mb={4} isRequired>
            <FormLabel>{t("contact:firstName")}</FormLabel>
            <Input
              type="text"
              placeholder="John"
              value={firstName}
              onChange={(ev) => setFirstName(ev.target.value)}
            />
          </FormControl>
          <FormControl id="lastName" mb={4} isRequired>
            <FormLabel>{t("contact:lastName")}</FormLabel>
            <Input
              type="text"
              placeholder="Doe"
              value={lastName}
              onChange={(ev) => setLastName(ev.target.value)}
            />
          </FormControl>
        </Grid>
        <FormControl id="email" mb={4} isRequired>
          <FormLabel>{t("contact:email")}</FormLabel>
          <Input
            type="email"
            placeholder="john.doe@doma.in"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
        </FormControl>
        <FormControl id="phone" mb={4} isRequired>
          <FormLabel>{t("contact:phone")}</FormLabel>
          <Input
            type="tel"
            placeholder="+351 912 345 678"
            value={phone}
            onChange={(ev) => setPhone(ev.target.value)}
          />
        </FormControl>

        <HStack spacing={2} justify="flex-end">
          <Button colorScheme="blue" variant="ghost" onClick={props.cancel}>
            {t("global:back")}
          </Button>
          <Button
            type="submit"
            colorScheme="blue"
            disabled={!firstName || !lastName || !email || !phone}
          >
            {t("contact:submit")}
          </Button>
        </HStack>
      </Box>
    </>
  );
}

export default Contact;
