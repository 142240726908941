import {
  Collapse,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useLocation } from "react-router-dom";

import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded
} from "@material-ui/icons";

import image1 from "../assets/tutorial/tutorial_1.svg";
import image2 from "../assets/tutorial/tutorial_2.svg";
import image3 from "../assets/tutorial/tutorial_3.svg";
import image4 from "../assets/tutorial/tutorial_4.svg";
import image5 from "../assets/tutorial/tutorial_5.svg";

interface TutorialCardProps {
  path: string;
  text: string;
}

function Tutorial() {
  const { t } = useTranslation();
  const location = useLocation();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Heading as="h1" size="md">
          {t("tutorial:title")}
        </Heading>

        <IconButton
          aria-label={isOpen ? "Collapse" : "Expand"}
          icon={
            isOpen ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />
          }
          variant="ghost"
          onClick={onToggle}
        />
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Grid templateColumns={["1fr", "1fr 1fr"]} gap={2}>
          <Switch location={location} key={location.pathname}>
            {/* Home */}
            <Route exact path="/">
              <TutorialCard path={image1} text="first" />
              <TutorialCard path={image2} text="second" />
            </Route>

            {/* Contact */}
            <Route path="/contact">
              <TutorialCard path={image3} text="third" />
              <TutorialCard path={image4} text="fourth" />
            </Route>

            {/* Confirm */}
            <Route path="/confirm">
              <TutorialCard path={image5} text="fifth" />
            </Route>
          </Switch>
        </Grid>
      </Collapse>
    </>
  );
}

export default Tutorial;

function TutorialCard(props: TutorialCardProps) {
  const { t } = useTranslation();

  return (
    <VStack>
      <Image src={props.path} alt="Tutorial"></Image>
      <Text>{t(`tutorial:${props.text}`)}</Text>
    </VStack>
  );
}
