import {
  Box,
  Flex,
  IconButton,
  Heading,
  Spacer,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip
} from "@chakra-ui/react";
import { HomeRounded, TranslateRounded } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Navbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Box as="nav" px={4} py={2} boxShadow="base" bgColor="white">
      <Flex align="center">
        <Box display="flex" alignItems="center">
          <Link to="/" style={{ lineHeight: "initial" }}>
            <HomeRounded />
          </Link>
          <Heading size="xs" pl={2}>
            {t("global:title")}
          </Heading>
        </Box>
        <Spacer />
        <Menu>
          <Tooltip
            label={t("global:language")}
            aria-label="A tooltip"
            placement="left"
          >
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<TranslateRounded />}
              variant="ghost"
            />
          </Tooltip>

          <MenuList>
            <MenuItem onClick={() => changeLanguage("en")}>
              🇬🇧 {t("global:english")}
            </MenuItem>
            <MenuItem onClick={() => changeLanguage("pt")}>
              🇵🇹 {t("global:portuguese")}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
}

export default Navbar;
