import {
  Box,
  Button,
  Divider,
  HStack,
  Grid,
  Heading,
  Text,
  Center,
  Icon,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  FormControl,
  FormLabel,
  Textarea
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DoneRounded, ErrorOutlineRounded } from "@material-ui/icons";

import { ContactInfo } from "../types/types";
import { getDuration } from "../util";

interface ConfirmProps {
  selectedDates: { from: Date | undefined; to: Date | undefined };
  contactInfo: ContactInfo;
  status: string | undefined;
  comments: string;
  setComments: (value: string) => void;
  cancel: () => void;
  confirm: () => void;
  resetStatus: () => void;
}

function Confirm(props: ConfirmProps) {
  const { t, i18n } = useTranslation();

  const getStatusKey = () => {
    if (props.status === "ok") return props.status;
    return "default";
  };

  return (
    <>
      <Heading as="h1" size="md" mb={2}>
        {t(`confirm:title.${getStatusKey()}`)}
      </Heading>
      <Text color="gray.600" mb={4}>
        {t(`confirm:subtitle.${getStatusKey()}`)}
      </Text>

      {props.status === "error" && (
        <Alert status="error" mb={4} borderRadius="md">
          <AlertIcon as={ErrorOutlineRounded} />
          <Box flex="1">
            <AlertTitle>{t("confirm:title.error")}</AlertTitle>
            <AlertDescription display="block">
              {t("confirm:subtitle.error")}
            </AlertDescription>
          </Box>
          <CloseButton
            position="absolute"
            right={2}
            top={2}
            onClick={props.resetStatus}
          />
        </Alert>
      )}

      <Box id="wrapper" boxShadow="base" rounded="md" bg="white" mb={4} p={4}>
        {props.status !== "ok" ? (
          <>
            <Heading as="h4" size="sm" mb={2}>
              {t("confirm:dates")}
            </Heading>
            <Grid templateColumns="1fr 2fr" columnGap={2}>
              <Text as="span" color="gray.600">
                {t("confirm:from")}
              </Text>
              <Text>
                {props.selectedDates.from?.toLocaleString(i18n.language, {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                  year: "numeric"
                })}
              </Text>

              <Text as="span" color="gray.600">
                {t("confirm:to")}
              </Text>
              <Text>
                {props.selectedDates.to?.toLocaleString(i18n.language, {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                  year: "numeric"
                })}
              </Text>

              <Text as="span" color="gray.600">
                {t("confirm:duration")}
              </Text>
              <Text>
                {props.selectedDates?.from &&
                  props.selectedDates?.to &&
                  `${
                    getDuration(
                      props.selectedDates.from,
                      props.selectedDates.to
                    ) + 1
                  } ${t("confirm:days")}, ${getDuration(
                    props.selectedDates.from,
                    props.selectedDates.to
                  )} ${t("confirm:nights")}`}
              </Text>
            </Grid>

            <Divider my={4} />

            <Heading as="h4" size="sm" mb={2}>
              {t("confirm:contact")}
            </Heading>
            <Grid templateColumns="1fr 2fr" columnGap={2} mb={4}>
              <Text as="span" color="gray.600">
                {t("confirm:name")}
              </Text>
              <Text>
                {`${props.contactInfo.firstName} ${props.contactInfo.lastName}`}
              </Text>

              <Text as="span" color="gray.600">
                {t("contact:email")}
              </Text>
              <Text>{props.contactInfo.email}</Text>

              <Text as="span" color="gray.600">
                {t("contact:phone")}
              </Text>
              <Text>{props.contactInfo.phone}</Text>
            </Grid>

            <Divider my={4} />

            <FormControl id="comments" mb={4}>
              <FormLabel>{t("contact:comments")}</FormLabel>
              <Textarea
                placeholder={t("contact:commentsPlaceholder")}
                value={props.comments}
                onChange={(ev) => props.setComments(ev.target.value)}
              />
            </FormControl>

            <HStack spacing={2} justify="flex-end">
              <Button
                colorScheme="blue"
                variant="ghost"
                onClick={props.cancel}
                disabled={props.status === "loading"}
              >
                {t("global:back")}
              </Button>
              <Button
                colorScheme="blue"
                onClick={props.confirm}
                isLoading={props.status === "loading"}
              >
                {t("global:confirm")}
              </Button>
            </HStack>
          </>
        ) : (
          <Center>
            <Icon
              as={DoneRounded}
              color="green.400"
              style={{ width: 128, height: 128 }}
            />
          </Center>
        )}
      </Box>
      <Text fontSize="xs" color="gray.600">
        {t("confirm:footer")}
      </Text>
    </>
  );
}

export default Confirm;
