import { Box, Text, Container, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <Box mt="auto" pt={12}>
      <Box bg="gray.100">
        <Container>
          <Text py={4}>
            {t("footer:text")}
            <Link href="https://joaosantos.dev/" color="blue.600" isExternal>
              João
            </Link>
          </Text>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;
