import { Scheduling } from "../types/types";
import { API_URL, POST_CONFIG } from "../util";

export const getSchedulings = async (
  date: number
): Promise<Array<Scheduling> | undefined> => {
  try {
    const data: Array<Scheduling> = await (
      await fetch(API_URL + `/schedulings?date=${date}`)
    ).json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getScheduling = async (
  id: string
): Promise<Scheduling | undefined> => {
  try {
    const data: Scheduling = await (
      await fetch(API_URL + `/schedulings?id=${id}`)
    ).json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const newScheduling = async (scheduling: Scheduling) => {
  try {
    const data = await (
      await fetch(API_URL + `/schedulings`, {
        ...POST_CONFIG,
        body: JSON.stringify(scheduling)
      })
    ).text();
    return data === "ok" ? data : "error";
  } catch (error) {
    console.error(error);
    return "error";
  }
};
