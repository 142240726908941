import { useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { Box, Container, Divider } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";

import Navbar from "./components/Navbar";
import Tutorial from "./components/Tutorial";
import Footer from "./components/Footer";

import Home from "./views/Home";
import Contact from "./views/Contact";
import Confirm from "./views/Confirm";
import Approval from "./views/Approval";

import { ContactInfo, Scheduling } from "./types/types";
import api from "./api";

function App() {
  const [selectedDates, setSelectedDates] =
    useState<{ from: Date | undefined; to: Date | undefined }>();
  const [contactInfo, setContactInfo] = useState<ContactInfo>();
  const [comments, setComments] = useState<string>("");
  const [status, setStatus] = useState<"loading" | "ok" | "error">();

  const history = useHistory();
  const location = useLocation();

  const setSelected = (from: Date | undefined, to: Date | undefined) => {
    setSelectedDates({
      from,
      to
    });
  };

  const setContact = (
    firstName: string,
    lastName: string,
    email: string,
    phone: string
  ) => {
    setContactInfo({
      firstName,
      lastName,
      email,
      phone
    });
  };

  const confirmDates = () => {
    history.push("/contact");
  };

  const confirmContact = () => {
    history.push("/confirm");
  };

  const cancelScheduling = () => {
    history.push("/");
  };

  const confirmScheduling = async () => {
    if (
      !selectedDates ||
      !selectedDates.from ||
      !selectedDates.to ||
      !contactInfo
    )
      return;

    setStatus("loading");

    const scheduling: Scheduling = {
      from: selectedDates.from.getTime(),
      to: selectedDates.to.getTime(),
      who: contactInfo,
      comments: comments
    };

    const status = await api.newScheduling(scheduling);
    setStatus(status);
  };

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-20px"
    },
    in: {
      opacity: 1,
      x: 0
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.25
  };

  return (
    <Box bgColor="gray.50" minH="100vh" display="flex" flexDirection="column">
      <Navbar></Navbar>

      <Container as={"main"} pt={8}>
        <AnimatePresence>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/">
              <motion.div
                initial="initial"
                animate="in"
                variants={pageVariants}
                transition={pageTransition}
              >
                <Home
                  selectedDates={selectedDates}
                  setSelected={setSelected}
                  confirm={confirmDates}
                ></Home>
              </motion.div>
            </Route>

            <Route path="/contact">
              {selectedDates ? (
                <motion.div
                  initial="initial"
                  animate="in"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Contact
                    setContact={setContact}
                    cancel={cancelScheduling}
                    confirm={confirmContact}
                  ></Contact>
                </motion.div>
              ) : (
                <Redirect to="/" />
              )}
            </Route>

            <Route path="/confirm">
              {selectedDates && contactInfo ? (
                <motion.div
                  initial="initial"
                  animate="in"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Confirm
                    selectedDates={selectedDates}
                    contactInfo={contactInfo}
                    status={status}
                    comments={comments}
                    setComments={setComments}
                    cancel={cancelScheduling}
                    confirm={confirmScheduling}
                    resetStatus={() => setStatus(undefined)}
                  ></Confirm>
                </motion.div>
              ) : (
                <Redirect to="/" />
              )}
            </Route>

            <Route
              path="/approval/:token"
              children={
                <motion.div
                  initial="initial"
                  animate="in"
                  variants={pageVariants}
                  transition={pageTransition}
                >
                  <Approval />
                </motion.div>
              }
            />
          </Switch>
        </AnimatePresence>

        {!location.pathname.includes("approval") && (
          <>
            <Divider my={8} />
            <Tutorial />
          </>
        )}
      </Container>

      <Footer />
    </Box>
  );
}

export default App;
