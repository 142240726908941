export const API_URL = process.env["REACT_APP_API_URL"] as string;

export const POST_CONFIG = {
  method: "POST",
  headers: {
    "Content-Type": "application/json"
  }
};

export const getDuration = (from: Date, to: Date) => {
  // @ts-ignore
  return Math.ceil(Math.abs(to - from) / (1000 * 60 * 60 * 24));
};
